.div-cards {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

.card {
    margin: 25px 50px;
    margin-right: 0 ! important;
    margin-left: 0 ! important;
    width: 700px;
    text-align: justify;
}

.venue {
    color: white;
    text-decoration: none;
}

.venue:hover {
    color: #d41367;
    text-decoration: none;
}