.register-btn {
    background-color: #d41367 !important;
}

.register-btn:hover {
    background-color: #89264f !important;
}

.register-btn a {
    text-decoration: none;
    color: white;
    width: 100%;
    height: 100%;
}